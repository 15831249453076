import React, { useState, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Stack,
  Alert,
  AlertTitle,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Backdrop,
  CircularProgress
} from "@mui/material";

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_ENDPOINT;

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#5E8D66',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#5E8D66',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#5E8D66',
    },
    '&:hover fieldset': {
      borderColor: '#5E8D66',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#5E8D66',
    },
  },
});

const FormConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3E4F44',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3E4F44',
    },
  },
  [`&.${stepConnectorClasses.error}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'red',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#C2D6C5",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const FormStepIconRoot = styled('div')(({ ownerState }) => ({
  color: '#C2D6C5',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#3E4F44',
  }),
  ...(ownerState.error && {
    color: 'red',
  }),
  '& .FormStepIcon-completedIcon': {
    color: '#3E4F44',
    zIndex: 1,
    fontSize: 18,
  },
  '& .FormStepIcon-errorIcon': {
    color: 'red',
    zIndex: 1,
    fontSize: 18,
  },
  '& .FormStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function FormStepIcon(props) {
  const { active, completed, error, className } = props;
  return (
    <FormStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        error ? (
          <CloseIcon className="FormStepIcon-errorIcon" />
        ) : (
          <Check className="FormStepIcon-completedIcon" />
        )
      ) : (
        <div className="FormStepIcon-circle" />
      )
      }
    </FormStepIconRoot>
  );
}

FormStepIcon.propTypes = {
  // Whether this step is active, @default false
  active: PropTypes.bool,
  className: PropTypes.string,
  // Mark this step as completed, passed to child components, @default false
  completed: PropTypes.bool,
  error: PropTypes.bool, // whether error is encountered
};

const Register = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLang = location.pathname.split('/')[1];

  // reCAPTCHA
  const recaptchaRef = useRef();

  // Stepper
  const [activeStep, setActiveStep] = useState(0);
  const [errSubmit, setErrSubmit] = useState(false);

  //const [captchaToken, setCaptchaToken] = useState('');

  const [formData, setFormData] = useState({
    consent: '',
    title: '',
    firstname: '',
    lastname: '',
    company: '',
    position: '',
    contact: '',
    email: ''
  });

  // Handle error input
  const [fnError, setFNError] = useState(false);
  const [lnError, setLNError] = useState(false);

  const [companyError, setCError] = useState(false);
  const [positionError, setPError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const steps = ["register.step1.title", "register.step2.title", "register.step3.title"];

  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (activeStep === 0 && formData.consent === 'no') {
      navigate(`${currentLang}`);
    } else if (activeStep === 1) {
      // use this checker to sum up correctly filled textfield
      var checker = 0;
      // check full name
      if (formData.firstname !== '' && /^[A-Za-z]+$/.test(formData.firstname)) {
        setFNError(false);
        checker += 1;
      } else {
        setFNError(true);
      }
      if (formData.lastname !== '' && /^[A-Za-z]+$/.test(formData.lastname)) {
        setLNError(false);
        checker += 1;
      } else {
        setLNError(true);
      }
      // check company name & position
      if (formData.company !== '') {
        setCError(false);
        checker += 1;
      } else {
        setCError(true);
      }
      if (formData.position !== '') {
        setPError(false);
        checker += 1;
      } else {
        setPError(true);
      }
      // check contact info
      if (formData.contact !== '' && /^\+\d+$/.test(formData.contact)) {
        setContactError(false);
        checker += 1;
      } else {
        setContactError(true);
      }
      if (formData.email !== '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
        setEmailError(false);
        checker += 1;
      } else {
        setEmailError(true);
      }
      if (checker === 6) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /*const handleCaptchaChange = async (token) => {
    if (!token) return;
    setCaptchaToken(token);
  }*/

  const handleSubmit = async (e) => {
    setLoading(true);
    console.log("client received command to submit form!");
    e.preventDefault();
    const recaptchaToken = await recaptchaRef.current.executeAsync(); // Get reCAPTCHA token
    recaptchaRef.current.reset();
    /*if (!captchaToken) {
      alert('Please complete the captcha challenge!');
      setLoading(false);
      setActiveStep((prevStep) => prevStep - 1);
      return;
    }*/

    try {
      // Verify reCAPTCHA token
      //axios.post('https://api.aucndecarbon.com.au/api/captcha-verify', { token: captchaToken })
      //axios.post('http://localhost:8080/api/captcha-verify', { token: captchaToken });
      //const verifyResponse = await axios.post('https://api.aucndecarbon.com.au/api/captcha-verify', { token: captchaToken });
      const verifyResponse = await axios.post('https://api.aucndecarbon.com.au/api/captcha-verify', {token: recaptchaToken})
      if (verifyResponse.data.success) {
        setErrSubmit(false);
        //axios.post('https://api.aucndecarbon.com.au/api/form-submit', formData)
        //axios.post('http://localhost:8080/api/form-submit', formData)
        const formResponse = await axios.post('https://api.aucndecarbon.com.au/api/form-submit', formData);
        if (formResponse.data.success) {
          setErrSubmit(false);
          console.log('Form submitted successfully!');
        } else {
          setErrSubmit(true);
          console.log('Unable to submit the form. ');
        }
      } else {
        setErrSubmit(true);
        console.log('Unable to verify ReCAPTCHA. ');
      }
    } catch (error) {
      setErrSubmit(true);
      console.error('Error during form submission: ', error);
    }
    setLoading(false);
    setActiveStep((prevStep) => prevStep + 1);

  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" sx={{ color: "#3E4F44" }}>{t("register.step1.header")}</Typography>
            <Typography variant="body1" sx={{ mt: 5, mb: 5 }}>
              {t("register.step1.body")}
            </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend" required
                sx={{
                  color: 'black',
                  '&.Mui-focused': {
                    color: '#5E8D66'
                  }
                }}
              >{t("register.step1.radio")}</FormLabel>
              <RadioGroup
                row
                name="consent"
                value={formData.consent}
                onChange={handleChange}
              >
                <FormControlLabel value="yes" control={
                  <Radio sx={{ color: 'black', '&.Mui-checked': { color: '#5E8D66' }, '&.Mui-checked:hover': { backgroundColor: '#C2D6C5' }, '&:hover': { backgroundColor: '#C2D6C5' } }} />
                } label={t("register.step1.radio-1")} />
                <FormControlLabel value="no" control={
                  <Radio sx={{ color: 'black', '&.Mui-checked': { color: '#5E8D66' }, '&.Mui-checked:hover': { backgroundColor: '#C2D6C5' }, '&:hover': { backgroundColor: '#C2D6C5' } }} />
                } label={t("register.step1.radio-2")} />
              </RadioGroup>
            </FormControl>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Stack spacing={0}>
              <Typography variant="h6" sx={{ color: "#3E4F44" }}>{t("register.step2.header")}</Typography>
              <Typography variant="body1" sx={{ mt: 5, mb: 5 }}>
                {t("register.step2.body")}
              </Typography>
              <Stack direction="row" spacing={5}>
                <CustomTextField
                  sx={{
                    width: '15%'
                  }}
                  variant="standard"
                  label={t("register.step2.q1")}
                  name="title"
                  select
                  defaultValue=""
                  value={formData.title}
                  onChange={handleChange}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: '#C2D6C5'
                          },
                          '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: '#C2D6C5',
                            '&:hover': {
                              backgroundColor: '#C2D6C5'
                            }
                          }
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                  <MenuItem value="Mrs">Mrs</MenuItem>
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Mx">Mx</MenuItem>
                  <MenuItem value="Dr">Dr</MenuItem>
                  <MenuItem value="Prof">Prof</MenuItem>
                  <MenuItem value="A/Prof">A/Prof</MenuItem>
                </CustomTextField>
                <CustomTextField
                  required
                  label={t("register.step2.q2")}
                  name="firstname"
                  variant="standard"
                  value={formData.firstname}
                  onChange={handleChange}
                  error={fnError}
                  helperText={t("register.step2.l1")}
                />
                <CustomTextField
                  required
                  label={t("register.step2.q3")}
                  name="lastname"
                  variant="standard"
                  value={formData.lastname}
                  onChange={handleChange}
                  error={lnError}
                  helperText={t("register.step2.l1")}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <CustomTextField
                  required
                  label={t("register.step2.q4")}
                  name="company"
                  variant="standard"
                  value={formData.company}
                  onChange={handleChange}
                  error={companyError}
                />
                <CustomTextField
                  required
                  label={t("register.step2.q5")}
                  name="position"
                  variant="standard"
                  value={formData.position}
                  onChange={handleChange}
                  error={positionError}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <CustomTextField
                  required
                  label={t("register.step2.q6")}
                  name="contact"
                  variant="standard"
                  value={formData.contact}
                  onChange={handleChange}
                  error={contactError}
                  helperText={t("register.step2.l2")}
                />
                <CustomTextField
                  required
                  label={t("register.step2.q7")}
                  name="email"
                  variant="standard"
                  value={formData.email}
                  onChange={handleChange}
                  error={emailError}
                  helperText={t("register.step2.l3")}
                />
              </Stack>
              
              <Box sx={{ height: '50px', width: "100%" }} />
            </Stack>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Stack spacing={0}>
              <Typography variant="h6" sx={{ color: "#3E4F44" }}>{t("register.step3.header")}</Typography>
              <Typography variant="body1" sx={{ mt: 5, mb: 5 }}>
                {t("register.step3.body")}
              </Typography>
            </Stack>
          </Box>
        );
      default: return;
    }
  };

  return (
    <Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
      <Box display="flex" justifyContent='center' sx={{ mt: 15, mb: 3, width: '100%' }}>
        <Typography variant="h5" sx={{ color: "#3E4F44", fontWeight: 'bold' }}>
          {t("register.title")}
        </Typography>
      </Box>
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<FormConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={(props) => (<FormStepIcon {...props} error={index === 2 ? errSubmit : false} />)}><Typography variant="subtitle1">{t(label)}</Typography></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2, mb: 5, width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={1} md={2} />
            <Grid item xs={10} md={8}>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress sx={{ color: 'white' }} />
              </Backdrop>
              {/* Invisible reCAPTCHA */}
              <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={'6LcDIHIqAAAAAAimKX7fDGeRBBACTTKSnJfJoUgm'} />
              {renderStepContent(activeStep)}
            </Grid>
            <Grid item xs={1} md={2} />
            <Grid item xs={1} md={2} />
            <Grid item xs={10} md={8}>
              {activeStep < steps.length ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 5 }}>
                  <Button color="inherit" disabled={activeStep === 0}
                    sx={{
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#C2D6C5',
                        boxShadow: 'none'
                      }
                    }}
                    onClick={handlePrev}
                  >
                    {t("register.b1")}
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button color="inherit" onClick={handleSubmit}
                      sx={{
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#C2D6C5',
                          boxShadow: 'none'
                        }
                      }}
                    >
                      {t("register.b3")}
                    </Button>
                  ) : (
                    <Button color="inherit" onClick={handleNext}
                      sx={{
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#C2D6C5',
                          boxShadow: 'none'
                        }
                      }}
                    >
                      {t("register.b2")}
                    </Button>
                  )}
                </Box>
              ) : (
                errSubmit ? (
                  <Box sx={{ mt: 2, mb: 5 }}>
                    <Stack spacing={0}>
                      <Typography variant="h6" sx={{ color: "red" }}>{t("register.failed.title")}</Typography>
                      <Typography variant="body1" sx={{ mt: 5, mb: 5 }}>{t("register.failed.body")}</Typography>
                      <Button color="inherit" onClick={() => window.location.reload()}
                        sx={{
                          textTransform: 'none',
                          mb: 5,
                          right: 10,
                          '&:hover': {
                            backgroundColor: '#C2D6C5',
                            boxShadow: 'none'
                          }
                        }}
                      >
                        {t("register.b4")}
                      </Button>
                    </Stack>
                  </Box>
                ) : (
                  <Box sx={{ mt: 2, mb: 5 }}>
                    <Stack spacing={0}>
                      <Typography variant="h6" sx={{ color: "#3E4F44" }}>{t("register.completed.title")}</Typography>
                      <Typography variant="body1" sx={{ mt: 5, mb: 5 }}>{t("register.completed.body")}</Typography>
                    </Stack>
                  </Box>
                )
              )}
            </Grid>
            <Grid item xs={1} md={2} />
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Register;