import React from "react";
import {
	Box,
	Typography,
	Button,
	Stack,
	Grid,
	Paper,
	ListItemText
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import HeaderBlock from "../utils/HeaderBlock";

const CallForAbstracts = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Box display="flex" justifyContent="center" sx={{ mt: 5, mb: 3, pl: 5, pt: 5, width: '100%' }}>
				<Typography variant="h4" sx={{ color: '#3E4F44' }}>
					{t("abstract.title")}
				</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={1} md={1.5} />
				<Grid item xs={10} md={9}>
					<Stack spacing={0}>
						<Typography variant="body1" sx={{ mt: 5 }}>
							{t("abstract.body1")}
						</Typography>
						<Typography variant="body1" sx={{ mt: 2 }}>
							{t("abstract.body2")}
							<Typography component="span" variant="body1" sx={{ textDecoration: 'underline', color: '#5E8D66' }}>
								abstract@aucndecarbon.com.au</Typography>.
						</Typography>
						<Typography variant="h5" sx={{ mt: 2 }}>
							{t("abstract.header1")}
						</Typography>
						<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }}>
							<ChevronRightIcon fontSize="large" sx={{ color: '#5E8D66' }} />
							<Typography variant="h6">
								{t("abstract.topic1")}
							</Typography>
						</Stack>
						<Box display="flex" sx={{ bgcolor: '#C2D6C5', pt: 5, pb: 5, pr: 5, pl: 5, width: '100%' }}>
							<Stack spacing={2} sx={{ bgcolor: 'transparent' }}>
								<Typography variant="body1">
									{t("abstract.t11")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t12")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t13")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t14")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t15")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t16")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t17")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t18")}
								</Typography>
							</Stack>
						</Box>
						<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }}>
							<ChevronRightIcon fontSize="large" sx={{ color: '#5E8D66' }} />
							<Typography variant="h6">
								{t("abstract.topic2")}
							</Typography>
						</Stack>
						<Box display="flex" sx={{ bgcolor: '#C2D6C5', pt: 5, pb: 5, pr: 5, pl: 5, width: '100%', mb: 5 }}>
							<Stack spacing={2} sx={{ bgcolor: 'transparent' }}>
								<Typography variant="body1">
									{t("abstract.t21")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t22")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t23")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t24")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t25")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t26")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t27")}
								</Typography>
								<Typography variant="body1">
									{t("abstract.t28")}
								</Typography>
							</Stack>
						</Box>
					</Stack>
				</Grid>
				<Grid item xs={1} md={1.5} />
			</Grid>
		</Stack>
	);
};

export default CallForAbstracts;