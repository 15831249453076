import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Stack,
  LinearProgress,
  IconButton,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  ListItemText
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';

import { useTranslation } from "react-i18next";

const Img = styled('img')({
  margin: 0,
  display: 'block',
  width: '100%'
});

const CarouselItem = styled(Box)(({ translateX }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  bgcolor: '#C2D6C5',
  transform: `translateX(${translateX}%)`,
  transition: 'transform 0.5s ease-in-out',
  display: 'flex'
}));


const Homepage = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  // carousel data
  const carouselData = [
    {
      image: '/images/carousel-1.jpg',
      title: 'homepage.carousel.carousel1.title',
      subtitle: 'homepage.carousel.carousel1.subtitle',
      description: 'homepage.carousel.carousel1.desc',
      button: 'homepage.carousel.carousel1.button',
      link: `/${currentLang}/attend/register-info`
    },
    {
      image: '/images/carousel-2.jpg',
      title: 'homepage.carousel.carousel2.title',
      subtitle: 'homepage.carousel.carousel2.subtitle',
      description: 'homepage.carousel.carousel2.desc',
      button: 'homepage.carousel.carousel2.button',
      link: `/${currentLang}/program/call-for-abstracts`
    },
    {
      image: '/images/carousel-3.jpg',
      title: 'homepage.carousel.carousel3.title',
      subtitle: 'homepage.carousel.carousel3.subtitle',
      description: 'homepage.carousel.carousel3.desc',
      button: 'homepage.carousel.carousel3.button',
      link: `/${currentLang}/program/keynote-speakers`
    }
  ];

  // Card Data
  const cardData = [
    {
      title: 'homepage.keydates.date1.title',
      description: 'homepage.keydates.date1.desc',
      button: 'homepage.keydates.date1.button',
      link: `/${currentLang}/program/call-for-abstracts`
    },
    {
      title: 'homepage.keydates.date2.title',
      description: 'homepage.keydates.date2.desc',
      button: 'homepage.keydates.date2.button',
      link: `/${currentLang}/program/call-for-abstracts`
    },
    {
      title: 'homepage.keydates.date3.title',
      description: 'homepage.keydates.date3.desc',
      button: 'homepage.keydates.date3.button',
      link: `/${currentLang}/attend/register-info`
    }
  ];

  // Contact info
  const contactData = [
    {
      title: 'homepage.contacts.contact1',
      email: 'abstract@aucndecarbon.com.au',
      telephone: '(0061) 123 456 789'
    },
    {
      title: 'homepage.contacts.contact2',
      email: 'cumtsafe@cumt.edu.cn',
      telephone: '(0086) 138 5208 6761'
    },
    {
      title: 'homepage.contacts.contact3',
      email: 'g.si@unsw.edu.au',
      telephone: '(0061) 2 9385 5727'
    }
  ];

  // carousel mgmt
  const [currentIdx, setCurrentIdx] = useState(0);
  // auto carousel timer
  const [cProgress, setCProgress] = useState(0);

  // timing control
  const timerRef = useRef(null);
  const pTimerRef = useRef(null);

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (pTimerRef.current) {
      clearInterval(pTimerRef.current);
    }

    timerRef.current = setInterval(() => {
      setCurrentIdx((prevIdx) => (prevIdx + 1) % carouselData.length);
      setCProgress(0);
    }, 5000);

    pTimerRef.current = setInterval(() => {
      setCProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 2));
    }, 100);
  }, [carouselData.length]);

  useEffect(() => {
    startTimer();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      if (pTimerRef.current) {
        clearInterval(pTimerRef.current);
      }
    };
  }, [startTimer]);

  // reset timer when user pressed a button
  const resetTimer = () => {
    setCProgress(0);
    startTimer();
  };

  const handleNext = () => {
    setCurrentIdx((prevIdx) => (prevIdx + 1) % carouselData.length);
    resetTimer();
  };
  const handlePrev = () => {
    setCurrentIdx((prevIdx) => (prevIdx - 1 + carouselData.length) % carouselData.length);
    resetTimer();
  };

  return (
    <Stack>
      <Box
        sx={{
          position: 'relative',
          top: 0,
          overflow: 'hidden',
          width: '100%',
          height: { md: '650px', xs: '680px' },
          bgcolor: '#C2D6C5'
        }}
      >
        {carouselData.map((item, index) => (
          <CarouselItem
            key={index}
            translateX={(index - currentIdx) * 100}
          >
            <Stack direction="row" spacing={0} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
                <Img alt={item.title} src={item.image} sx={{ height: '650px' }} />
              </Box>
              <Box sx={{ bgcolor: '#C2D6C5', width: '500px', height: '650px', position: 'absolute', top: 0, left: 0, zIndex: 2 }}>
                <Stack spacing={3} sx={{ height: '650px', display: 'flex' }} alignItems="center" justifyContent="center">
                  <Typography variant="h4" sx={{ color: '#3E4F44', textAlign: 'center', pl: '15%', pr: '15%' }}>
                    {t(item.title)}
                  </Typography>
                  <Typography variant="h5" sx={{ color: '#3E4F44', textAlign: 'center', pl: '15%', pr: '15%' }}>
                    {t(item.subtitle)}
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#3E4F44', textAlign: 'center', pl: '15%', pr: '15%' }}>
                    {t(item.description)}
                  </Typography>
                  <Button variant="contained" size="large" sx={{
                    fontWeight: 700,
                    backgroundColor: '#5E8D66',
                    color: 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(194, 214, 197, 0.5)',
                      color: '#3E4F44',
                      boxShadow: 'none'
                    }
                  }}
                    component={Link} to={item.link}
                  >
                    {t(item.button)}
                  </Button>
                </Stack>
              </Box>
            </Stack>
            <Stack direction="column" spacing={0} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Box sx={{ position: 'relative' }}>
                <Img alt={item.title} src={item.image} sx={{ width: '100%' }} />
              </Box>
              <Box sx={{ bgcolor: '#C2D6C5', width: '100%', position: 'relative' }} alignItems="center">
                <Stack spacing={2} sx={{ width: '100%', display: 'flex', mt: 3, mb: 3 }} alignItems="center" justifyContent="center">
                  <Typography variant="h5" sx={{ fontWeight: 700, color: '#3E4F44', textAlign: 'center', pl: '15%', pr: '15%' }}>
                    {t(item.title)}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700, color: '#3E4F44', textAlign: 'center', pl: '15%', pr: '15%' }}>
                    {t(item.subtitle)}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: '#3E4F44', textAlign: 'center', pl: '15%', pr: '15%' }}>
                    {t(item.description)}
                  </Typography>
                  <Button variant="contained" size="large" sx={{
                    backgroundColor: '#5E8D66',
                    color: 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(194, 214, 197, 0.5)',
                      color: '#3E4F44',
                      boxShadow: 'none'
                    }
                  }}
                    component={Link} to={item.link}
                  >
                    {t(item.button)}
                  </Button>
                </Stack>
              </Box>
            </Stack>

          </CarouselItem>
        ))}
        <IconButton onClick={handlePrev} sx={{ position: 'absolute', top: { md: '50%', xs: '45%' }, left: 0, transform: 'translateY(-50%)', zIndex: 10, color: "white" }}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton onClick={handleNext} sx={{ position: 'absolute', top: { md: '50%', xs: '45%' }, right: 0, transform: 'translateY(-50%)', zIndex: 10, color: "white" }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent={"center"} gap={2} mt={2}>
        <LinearProgress variant="determinate" value={currentIdx <= 0 ? cProgress : 100} sx={{ width: '5%', backgroundColor: "#C2D6C5", '& .MuiLinearProgress-bar': { backgroundColor: '#3E4F44' } }} />
        <LinearProgress variant="determinate" value={currentIdx < 1 ? 0 : (currentIdx === 1 ? cProgress : 100)} sx={{ width: '5%', backgroundColor: "#C2D6C5", '& .MuiLinearProgress-bar': { backgroundColor: '#3E4F44' } }} />
        <LinearProgress variant="determinate" value={currentIdx < 2 ? 0 : cProgress} sx={{ width: '5%', backgroundColor: "#C2D6C5", '& .MuiLinearProgress-bar': { backgroundColor: '#3E4F44' } }} />
      </Box>
      <Box key="hosts" display="flex" justifyContent={'center'} alignItems={'center'} gap={2} mt={5}>
        <Stack direction="row" spacing={5} key="hosts" justifyContent={'center'} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Img alt={'NFACR'} src={'/logos/nfacr-logo.png'} sx={{ height: '20vh', cursor: 'pointer' }}
            onClick={() => window.open('https://www.australiachinafoundation.org.au/', '_blank')}
          />
          <Img alt={'UNSW Sydney'} src={'/logos/unsw-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
            onClick={() => window.open('https://www.unsw.edu.au/', '_blank')}
          />
          <Img alt={'CUMT'} src={'/logos/cumt-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
            onClick={() => window.open('https://global.cumt.edu.cn/', '_blank')}
          />
          <Img alt={'SPU'} src={'/logos/spu-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
            onClick={() => window.open('https://www.swpu.edu.cn/en/', '_blank')}
          />
        </Stack>
        <Stack direction="column" spacing={5} key="hosts" justifyContent={'center'} alignItems={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Img alt={'NFACR'} src={'/logos/nfacr-logo.png'} sx={{ width: '50%', cursor: 'pointer' }}
            onClick={() => window.open('https://www.australiachinafoundation.org.au/', '_blank')}
          />
          <Img alt={'UNSW Sydney'} src={'/logos/unsw-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
            onClick={() => window.open('https://www.unsw.edu.au/', '_blank')}
          />
          <Img alt={'CUMT'} src={'/logos/cumt-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
            onClick={() => window.open('https://global.cumt.edu.cn/', '_blank')}
          />
          <Img alt={'SPU'} src={'/logos/spu-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
            onClick={() => window.open('https://www.swpu.edu.cn/en/', '_blank')}
          />
        </Stack>
      </Box>
      <Box display="flex" justifyContent={'center'} alignItems={'center'} gap={2} mt={10}>
        <Typography variant="h3" sx={{ color: '#3E4F44', textAlign: 'center', ml: '15%', mr: '15%' }}>
          {t('homepage.titles.title1')}
        </Typography>
      </Box>
      <Stack mt={5} direction='row' justifyContent={'center'} alignItems={'center'} spacing={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
        {cardData.map((item, index) => (
          <Paper sx={{ height: '200px', width: '20%', bgcolor: '#C2D6C5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ ml: 2, mr: 2, mt: 2 }}>
              <Typography variant="h6" sx={{ color: '#3E4F44' }}>
                {t(item.title)}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3E4F44' }}>
                {t(item.description)}
              </Typography>
            </Box>
            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
            <Button
              sx={{
                backgroundColor: 'transparent',
                color: '#5E8D66',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#3E4F44',
                  boxShadow: 'none'
                }
              }}
              component={Link} to={item.link}>
              {t(item.button)}
            </Button>
            </Box>
          </Paper>
        ))}
      </Stack>
      <Stack mt={5} direction='column' justifyContent={'center'} alignItems={'center'} spacing={0} sx={{ display: { xs: 'flex', md: 'none' } }}>
        {cardData.map((item, index) => (
          <Paper sx={{ mt: 4, height: '30vh', width: '50%', bgcolor: '#C2D6C5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ ml: 2, mr: 2, mt: 2 }}>
            <Typography variant="h6" sx={{ color: '#3E4F44' }}>
              {t(item.title)}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#3E4F44' }}>
              {t(item.description)}
            </Typography>
          </Box>
          <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
          <Button
            sx={{
              backgroundColor: 'transparent',
              color: '#5E8D66',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#3E4F44',
                boxShadow: 'none'
              }
            }}
            component={Link} to={item.link}>
            {t(item.button)}
          </Button>
          </Box>
        </Paper>
        ))}
      </Stack>
      <Box display="flex" justifyContent={'center'} alignItems={'center'} gap={2} mt={10} mb={5}>
        <Typography variant="h4" sx={{ color: '#3E4F44', textAlign: 'center', ml: '15%', mr: '15%' }}>
          {t('homepage.titles.title2')}
        </Typography>
      </Box>
      <Box display="flex" width='100%' sx={{ bgcolor: '#C2D6C5' }} pt={3} pb={3}>
        <Grid container Spacing={2}>
          <Grid item xs={1} md={2} />
          <Grid item xs={10} md={8}>
            <Stack spacing={1}>
              <Typography variant="body1" sx={{ color: '#3E4F44' }}>
                The organising committee of the First China-Australia Summit Forum on Decarbonisation the Energy and
                Resource Sectors (AUCN DeCarbon 2025), in collaboration with the National Foundation for Australia-China
                Relation, the University of New South Wales (UNSW) and China University of Mining and Technology (CUMT),
                is pleased to announce the First China-Australia Summit Forum on Decarbonisation Development that will be
                held in Xuzhou, China from 11 to 13 April 2025.
              </Typography>
              <Typography variant="body1" sx={{ color: '#3E4F44' }}>
                Greenhouse gas emissions from extracting energy and resources account for ~10% of carbon emissions in
                Australia and China. The First China-Australia Summit Forum on Decarbonisation Development focuses on
                the critical areas of resource development and coal mining industries. This forum brings together experts,
                researchers, and industry leaders from both countries to address key issues in reducing carbon emissions
                within these sectors. It provides a unique platform for promoting sustainable practices, enhancing
                productivity, and improving operational effectiveness. Participants will have the opportunity to exchange
                knowledge, explore innovative technologies, and foster collaborations aimed at achieving significant
                decarbonisation, thereby contributing to global sustainability efforts.
              </Typography>
              <Typography variant="body1" sx={{ color: '#3E4F44' }}>
                AUCN DeCarbon 2025 will be a peer-reviewed international conference. Oral presentations are scheduled to
                run for 2 days, with 1 additional day for workshops and site tours. The conference will include full-day
                technical presentations, workshops, exhibitor booths, networking and social activities. An accompanying
                partners' program is also planned.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
      </Box>
      <Box width='100%' height='10vh' sx={{ bgcolor: '#C2D6C5' }} />
    </Stack>
  );
};

export default Homepage;