import React from "react";
import { Box, Typography, Button, Stack, Grid, Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderBlock from "../utils/HeaderBlock";

const RegisterInfo = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  const handleDownload = () => {
    const filePath = `${process.env.PUBLIC_URL}/Conference-Details.pdf`;

    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'Conference-Details.pdf';

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by removing the link element
    link.remove();
  };

  return (
    <Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
      <HeaderBlock />
      <Box display="flex" justifyContent="center" sx={{ mt: 5, mb: 3, width: '100%' }}>
        <Typography variant="h5" sx={{ color: '#3E4F44', ml: 5, mr: 5 }}>
          {t("register-info.title")}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={10} md={9}>
          <Stack spacing={1}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We are excited to invite you to register for the First China-Australia Summit Forum on Decarbonisation the Energy and 
              Resource Sectors (AUCN DeCarbon 2025), taking place in Xuzhou, China from April 11 to 13, 2025. There are two types of 
              registration available: Full Registration and Student Registration. 
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: '#5E8D66', cursor: 'pointer', textDecoration: 'underline' }} onClick={handleDownload}>
              Click here to download the digital pamphlet.
            </Typography>
            <Typography variant="h5" sx={{ mb: 1 }}>
              {t("register-info.header")}
            </Typography>
            <Typography variant="h6">
              {t("register-info.header1")}
            </Typography>
            <Typography variant="h6">
              {t("register-info.header2")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} md={1.5} />
      </Grid>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#3E4F44',
          mt: 2,
          mb: 2
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%', height: '100px' }}
        >
          <Typography variant="h6" sx={{ color: 'white', ml: { md: 15, xs: 5 } }}>
            {t("register-info.break-title")}
          </Typography>
          <Button component={Link} to={`/${currentLang}/attend/register`}
            variant="contained"
            sx={{
              mr: { md: 15, xs: 5 } ,
              color: '#3E4F44',
              bgcolor: '#C2D6C5',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'white',
                boxShadow: 'none'
              }
            }}
          >
            {t("register-info.break-button")}
          </Button>
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={10} md={9}>
          <Stack spacing={1} sx={{ mb: 5 }}>
            <Typography variant="h5">
              {t("register-info.header3")}
            </Typography>
            <Typography variant="body1">
            Full Registration for AUCN DeCarbon 2025 grants participants access to the comprehensive range of activities and sessions 
            offered during the conference. This includes entry to all oral presentations, which will cover critical topics in reducing 
            carbon emissions within the energy and resource sectors. Attendees with full registration can also participate in workshops, 
            visit exhibitor booths, and engage in networking and social activities designed to foster collaboration between experts, 
            researchers, and industry leaders. Moreover, the Full Registration includes the opportunity to join site tours and attend 
            the accompanying partners' program, providing a holistic experience of the conference.
            </Typography>
            <Typography variant="h5">
              {t("register-info.header4")}
            </Typography>
            <Typography variant="body1">
            The Student Registration is tailored to support the participation of emerging scholars and students with an interest in 
            decarbonisation and sustainable practices. Priced at 1000 CNY, this registration type offers the same comprehensive access 
            to the oral presentations, workshops, and networking events as the Full Registration. It provides an invaluable platform 
            for students to engage with leading experts in the field, explore innovative technologies, and build professional networks 
            that will benefit their academic and future career paths.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} md={1.5} />
      </Grid>
    </Stack>
  );
};

export default RegisterInfo;