import React from "react";
import { Box, Typography, Button, Stack, Grid, Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import HeaderBlock from "../utils/HeaderBlock";
import MapBox from "../utils/MapBox";

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%'
});

const Venue = () => {
	// localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Box display="flex" justifyContent="center" sx={{ ml: 5, mr: 5, mt: 5, mb: 3, width: '100%' }}>
				<Typography variant="h4" sx={{ color: '#3E4F44', display: { xs: 'none', md: 'flex' } }}>
					{t("venue.title")}
				</Typography>
				<Typography variant="h5" sx={{ color: '#3E4F44', display: { xs: 'flex', md: 'none' } }}>
					{t("venue.title")}
				</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={1} md={1.5} />
				<Grid item xs={10} md={9}>
					<Stack spacing={1} sx={{ mb: 5 }}>
						<Typography variant="body1">
							<Typography component="span" variant="body1" sx={{ fontWeight: 'bold' }}>{t("venue.b1")}</Typography>
							{t("venue.t1")}
						</Typography>
						<Typography variant="body1" sx={{ textDecoration: 'none', color: 'black', '&:hover': { color: '#5E8D66' }, cursor: 'pointer' }} component={Link} to="https://www.marriott.com/en-us/hotels/xuzsi-sheraton-xuzhou/overview/" target="_blank">
							<Typography component="span" variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>{t("venue.b2")}</Typography>
								https://www.marriott.com/en-us/hotels/xuzsi-sheraton-xuzhou/overview/
						</Typography>
						<Typography variant="body1">
							<Typography component="span" variant="body1" sx={{ fontWeight: 'bold' }}>{t("venue.b3")}</Typography>
							+86 516-85088888
						</Typography>
					</Stack>
				</Grid>
				<Grid item xs={1} md={1.5} />
			</Grid>
			<Box display="flex" justifyContent="center" sx={{ mt: 1, mb: 1, width: '100%' }}>
				<Img alt="Venue-1" src='/images/venue-1.jpg' sx={{ width: '75%', maxWidth: '700px' }} />
			</Box>
			<Box display="flex" justifyContent="center" sx={{ mt: 1, mb: 1, width: '100%' }}>
				<Img alt="Venue-2" src='/images/venue-2.jpg' sx={{ width: '75%', maxWidth: '700px' }} />
			</Box>
			<Box display="flex" justifyContent="center" sx={{ mt: 1, mb: 1, width: '100%' }}>
				<Img alt="Venue-3" src='/images/venue-3.jpg' sx={{ width: '75%', maxWidth: '700px' }} />
			</Box>
			<Box display="flex" justifyContent="center" sx={{ mt: 5, mb: 3, width: '100%' }}>
				<Typography variant="h4" sx={{ color: '#3E4F44' }}>
					{t("venue.header")}
				</Typography>
			</Box>
			<Box display="flex" justifyContent="center" sx={{ mt: 1, mb: 3, width: '100%' }}>
				<Img alt="Map Screenshot" src='/images/direction.jpg' sx={{ width: '80%', maxWidth: '800px' }} />
			</Box>
			<Box display="flex" justifyContent={"center"} sx={{ mt: 5, mb: 5, width: '100%' }}>
				<MapBox />
			</Box>
			
		</Stack>
	);
};

export default Venue;