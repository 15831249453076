
/**                   Libraries used                   **/

import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import './i18n';


/**                   Components                   **/

// Custom theme
import theme from "./theme";
import { ThemeProvider, CssBaseline } from '@mui/material';

// use Layout to bind all other main components together
import Layout from "./Components/Layout";
import ScrollToTop from "./Components/utils/ScrollToTop";

// common page components
import Homepage from "./Components/common/Homepage";
import Sponsors from "./Components/common/Sponsors";
import Committee from "./Components/common/Committee";
import Contact from "./Components/common/Contact";

// attend page components
import RegisterInfo from "./Components/attend/RegisterInfo";
import Venue from "./Components/attend/Venue";
import FAQ from "./Components/attend/FAQ";
import Register from "./Components/attend/Register";

// program page components
import Program from "./Components/program/Program";
import Speakers from "./Components/program/Speakers";
import CallForAbstracts from "./Components/program/CallForAbstracts";

// This points to the server url
const SERVER_URL = process.env.REACT_APP_ENDPOINT;

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Layout>
        <Routes>
          <Route path="/:lang/" element={<Homepage />} />

          <Route path="/:lang/sponsors" element={<Sponsors />} />
          <Route path="/:lang/committee" element={<Committee />} />
          <Route path="/:lang/contact" element={<Contact />} />

          <Route path="/:lang/attend/register-info" element={<RegisterInfo />} />
          <Route path="/:lang/attend/register" element={<Register />} />
          <Route path="/:lang/attend/venue" element={<Venue />} />
          <Route path="/:lang/attend/faq" element={<FAQ />} />

          <Route path="/:lang/program/conference-program" element={<Program />} />
          <Route path="/:lang/program/keynote-speakers" element={<Speakers />} />
          <Route path="/:lang/program/call-for-abstracts" element={<CallForAbstracts />} />

          <Route path="*" element={<Navigate to="/en/" replace />} />
        </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
