import React from 'react';
import { Box } from '@mui/material';

import Navbar from './Navbar';
import FooterBlock from './utils/FooterBlock';
import FloatingButton from './utils/FloatingButton';

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Box component="header">
        <Navbar />
      </Box>
      <Box component="main" sx={{ flex: '1 0 auto' }}>
        {children}
      </Box>
      <Box component="footer">
        <FooterBlock />
        <FloatingButton />
      </Box>
    </Box>
  );
};

export default Layout;