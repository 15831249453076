import React from "react";
import {
	Box,
	Typography,
	Button,
	Stack,
	Grid,
	Paper
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import HeaderBlock from "../utils/HeaderBlock";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: 0,
	'&::before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#3E4F44" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: 'white',
	color: '#3E4F44',
	fontWeight: 'bold',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: '#C2D6C5',
	color: '#3E4F44',
	border: 0,
}));

const FAQ = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Grid container spacing={2}>
				<Grid item xs={1} md={2} />
				<Grid item xs={10} md={8}>
					<Stack spacing={2} sx={{ mt: 5, mb: 5 }}>
						<Typography variant="body1" sx={{ color: '#3E4F44' }}>
							See below for some of the most commonly asked questions about our conference.
						</Typography>
						<Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3E4F44' }}>
							Registration
						</Typography>
						<Accordion>
							<AccordionSummary>
								How do I register for the conference?
							</AccordionSummary>
							<AccordionDetails>
								You can register for the conference by visiting the Registration Page.
								Fill out the required information and complete the payment process to confirm your registration.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								What is the deadline for registration?
							</AccordionSummary>
							<AccordionDetails>
								There is no set deadline for registration, however, we do encourage early registration to ensure
								your spot at the conference since the seatings are limited.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Can I register on the day of the conference?
							</AccordionSummary>
							<AccordionDetails>
								On-site registration may be available, but we recommend registering online in advance
								to guarantee your participation.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Are there any group discounts available?
							</AccordionSummary>
							<AccordionDetails>
								Please contact us for more details.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Will I receive a confirmation email after registering?
							</AccordionSummary>
							<AccordionDetails>
								Yes, a confirmation email will be sent to the email address you provided during registration.
								Please check your spam/junk folder if you don't receive it within a few minutes.
							</AccordionDetails>
						</Accordion>
						<Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3E4F44' }}>
							Fees
						</Typography>
						<Accordion>
							<AccordionSummary>
								What are the registration fees?
							</AccordionSummary>
							<AccordionDetails>
								The registration fees vary depending on the type of participant
								(e.g., student, academic, professional). Please refer to the Registration page for more information.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								What is included in the registration fee?
							</AccordionSummary>
							<AccordionDetails>
								The registration fee includes access to all conference sessions, materials, refreshments, and any social events
								outlined in the conference program.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Can I get a refund if I cancel my registration?
							</AccordionSummary>
							<AccordionDetails>
								Refunds may be available if you cancel before the event start date.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Are there any additional fees I should be aware of?
							</AccordionSummary>
							<AccordionDetails>
								Apart from the registration fee, you are responsible for your own travel and accommodation.
							</AccordionDetails>
						</Accordion>
						<Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3E4F44' }}>
							Submissions
						</Typography>
						<Accordion>
							<AccordionSummary>
								How do I submit an abstract for the conference?
							</AccordionSummary>
							<AccordionDetails>
								Abstracts can be submitted through the Abstract Submission page. Please follow the instructions provided.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								What is the deadline for abstract submission?
							</AccordionSummary>
							<AccordionDetails>
								The deadline for abstract submission is 15 December 2024.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Can I submit more than one abstract?
							</AccordionSummary>
							<AccordionDetails>
								Yes, multiple submissions are allowed. However, each abstract must be submitted separately and follow the
								submission guidelines.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								When will I be notified about the acceptance of my abstract?
							</AccordionSummary>
							<AccordionDetails>
								We will start rolling out acceptance notifications from 15 January 2025.
								If you do not receive a notification, please contact us.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								What are the guidelines for full paper submission?
							</AccordionSummary>
							<AccordionDetails>
								Once you have received a notification of acceptance, you can start working on the full paper and submit by April 2025.
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary>
								Will my paper be published in the conference proceedings?
							</AccordionSummary>
							<AccordionDetails>
								Accepted papers will be published in the conference proceedings, provided that at least one author has
								registered for the conference and presented the paper.
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Grid>
				<Grid item xs={1} md={2} />
			</Grid>
		</Stack>
	);
};

export default FAQ;