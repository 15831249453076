import React from "react";
import {
  Box,
  Typography,
  Stack
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import '@fontsource/outfit/700.css';

const FooterBlock = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#3E4F44',
        mt: 0,
        width: '100%',
        position: 'relative',
        bottom: 0
      }}
    >
      <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ height: "5vh" }} />
        <Typography variant="h4" sx={{
          color: '#C2D6C5',
          fontFamily: '"Outfit", sans-serif',
          fontWeight: 700
        }}>
          AUCN DeCarbon
        </Typography>
        <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Typography variant="subtitle1" sx={{ color: '#C2D6C5' }}>
            {t('footer')}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#C2D6C5' }}>
            |
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#C2D6C5' }}>
            All Rights Reserved
          </Typography>
        </Stack>
        <Stack spacing={0} sx={{ alignItems: 'center', justifyContent: 'center', display: { xs: 'flex', md: 'none' } }}>
          <Typography variant="subtitle1" sx={{ color: '#C2D6C5', textAlign: 'center', ml: '15%', mr: '15%' }}>
          {t('footer')}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#C2D6C5', textAlign: 'center', ml: '15%', mr: '15%' }}>
            All Rights Reserved
          </Typography>
        </Stack>
        <Box sx={{ height: '5vh' }} />
      </Stack>
    </Box>
  );
};

export default FooterBlock;