import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NewReleasesIcon from '@mui/icons-material/NewReleases';

import HeaderBlock from "../utils/HeaderBlock";

const Sponsors = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 5, mb: 3, width: '100%' }}>
				<Box display="flex" justifyContent='center' alignItems='center' sx={{ bgcolor: '#C2D6C5', maxWidth: '800px', width: '75%', pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none' }}>
					<Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
						<NewReleasesIcon fontSize="large" sx={{ color: '#3E4F44' }} />
						<Typography variant="h5" sx={{ color: '#3E4F44', textAlign: 'center' }}>
							{t("sponsors")}
						</Typography>
					</Stack>
				</Box>
			</Box>
		</Stack>
	);
};

export default Sponsors;