import { createTheme } from "@mui/material";
import '@fontsource/noto-sans-sc/400.css';
import '@fontsource/noto-sans-sc/700.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Noto Sans SC Variable, sans-serif',
    allVariants: {
      fontFamily: 'Noto Sans SC Variable, sans-serif',
    },
    h3: {
      fontFamily: 'Noto Sans SC Variable, sans-serif',
      fontWeight: 700
    },
    h4: {
      fontFamily: 'Noto Sans SC Variable, sans-serif',
      fontWeight: 700
    },
    body1: {
      fontFamily: 'sans-serif',
    },
    body2: {
      fontFamily: 'sans-serif',
    },
    caption: {
      fontFamily: 'sans-serif',
    },
  }
});

export default theme;