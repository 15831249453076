import React, { useState } from "react";
import {
	Box,
	Typography,
	Stack,
	Paper,
	Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PersonIcon from '@mui/icons-material/Person';

import HeaderBlock from "../utils/HeaderBlock";

const Img = styled('img')({
	margin: 0,
	display: 'block',
	width: '100%'
});

const Committee = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Stack spacing={0} justifyContent="center" alignItems={"center"} sx={{ mt: 5, width: '100%' }}>
				<Typography variant="h4" sx={{ color: '#3E4F44', mb: 5 }}>
					{t("committee.h1")}
				</Typography>
				<Stack spacing={5} direction="row" justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
					<Img alt={'NFACR'} src={'/logos/nfacr-logo.png'} sx={{ height: '20vh', cursor: 'pointer' }}
						onClick={() => window.open('https://www.australiachinafoundation.org.au/', '_blank')}
					/>
					<Img alt={'UNSW Sydney'} src={'/logos/unsw-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
						onClick={() => window.open('https://www.unsw.edu.au/', '_blank')}
					/>
					<Img alt={'CUMT'} src={'/logos/cumt-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
						onClick={() => window.open('https://global.cumt.edu.cn/', '_blank')}
					/>
					<Img alt={'SPU'} src={'/logos/spu-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
						onClick={() => window.open('https://www.swpu.edu.cn/en/', '_blank')}
					/>
				</Stack>
				<Stack direction="column" spacing={5} key="hosts" justifyContent={'center'} alignItems={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
					<Img alt={'NFACR'} src={'/logos/nfacr-logo.png'} sx={{ width: '50%', cursor: 'pointer' }}
						onClick={() => window.open('https://www.australiachinafoundation.org.au/', '_blank')}
					/>
					<Img alt={'UNSW Sydney'} src={'/logos/unsw-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
						onClick={() => window.open('https://www.unsw.edu.au/', '_blank')}
					/>
					<Img alt={'CUMT'} src={'/logos/cumt-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
						onClick={() => window.open('https://global.cumt.edu.cn/', '_blank')}
					/>
					<Img alt={'SPU'} src={'/logos/spu-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
						onClick={() => window.open('https://www.swpu.edu.cn/en/', '_blank')}
					/>
				</Stack>
			</Stack>
			<Stack spacing={0} justifyContent="center" alignItems={"center"} sx={{ mt: 5, width: '100%' }}>
				<Box display="flex" justifyContent="center" alignItems={"center"} sx={{ width: '100%' }}>
					<Typography variant="h4" sx={{ color: '#3E4F44', mb: 5 }}>
						{t("committee.h2")}
					</Typography>
				</Box>
				<Stack direction="row" spacing={5} justifyContent="center" sx={{ width: '100%', display: { xs: "none", md: 'flex' } }}>
					<Paper square display="flex" justifyContent="center" alignItems="center" sx={{ bgcolor: '#C2D6C5', maxWidth: '400px', width: '45%', height: '75vh', maxHeight: '400px', pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none', mb: 2 }}>
						<Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
							<Img alt="A/Prof Guangyao Si" src="/people/guangyao-si.jpg" sx={{ width: '150px' }} />
							<Typography variant="h6">
								A/Prof Guangyao Si (UNSW)
							</Typography>
						</Stack>
					</Paper>
					<Paper square display="flex" justifyContent="center" alignItems="center" sx={{ bgcolor: '#C2D6C5', maxWidth: '400px', width: '45%', height: '75vh', maxHeight: '400px', pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none', mb: 2 }}>
						<Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
							<Img alt="A/Prof Guangyao Si" src="/people/zhai-cheng.jpg" sx={{ width: '150px' }} />
							<Typography variant="h6">
								Prof Cheng Zhai (CUMT)
							</Typography>
						</Stack>
					</Paper>
				</Stack>
				<Paper square justifyContent="center" alignItems="center" sx={{
					bgcolor: '#C2D6C5', maxWidth: '400px', width: '60%', height: '75vh', maxHeight: '400px',
					pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none', mb: 2, display: { xs: 'flex', md: 'none' }
				}}>
					<Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
						<Img alt="A/Prof Guangyao Si" src="/people/guangyao-si.jpg" sx={{ width: '150px' }} />
						<Typography variant="h6" sx={{ textAlign: 'center' }}>
							A/Prof Guangyao Si (UNSW)
						</Typography>
					</Stack>
				</Paper>
				<Paper square justifyContent="center" alignItems="center" sx={{
					bgcolor: '#C2D6C5', maxWidth: '400px', width: '60%', height: '75vh', maxHeight: '400px',
					pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none', mb: 2, display: { xs: 'flex', md: 'none' }
				}}>
					<Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
						<Img alt="A/Prof Guangyao Si" src="/people/zhai-cheng.jpg" sx={{ width: '150px' }} />
						<Typography variant="h6" sx={{ textAlign: 'center' }}>
							Prof Cheng Zhai (CUMT)
						</Typography>
					</Stack>
				</Paper>
			</Stack>
			<Stack spacing={0} justifyContent="center" alignItems={"center"} sx={{ mt: 5, width: '100%' }}>
				<Box display="flex" justifyContent="center" alignItems={"center"} sx={{ width: '100%' }}>
					<Typography variant="h4" sx={{ color: '#3E4F44', mb: 5, textAlign: 'center', pl: 5, pr: 5 }}>
						{t("committee.h3")}
					</Typography>
				</Box>
			</Stack>
			<Grid container spacing={2} sx={{ mb: 5 }}>
				<Grid item xs={1} md={1.5} />
				<Grid item xs={10} md={9}>
					<Paper square sx={{ bgcolor: '#C2D6C5', pt: 3, pb: 3, pl: 3, pr: 3, width: '100%', boxShadow: 'none' }}>
						<Stack spacing={2}>
							<Typography variant="h6">
								Dr Yu Jing, UNSW
							</Typography>
							<Typography variant="h6">
								Prof Ismet Canbult, UNSW
							</Typography>
							<Typography variant="h6">
								Prof Yuanping Cheng, CUMT
							</Typography>
							<Typography variant="h6">
								Prof Baiquan Lin, CUMT
							</Typography>
							<Typography variant="h6">
								Prof Liang Wang, CUMT
							</Typography>
							<Typography variant="h6">
								Prof Yulong Zhao, SWPU
							</Typography>
							<Typography variant="h6">
								Prof Qingquan Liu, CUMT
							</Typography>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={1} md={1.5} />
			</Grid>
		</Stack>
	);
};

export default Committee;