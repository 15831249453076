import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Stack
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Countdown = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  const calculateTimeLeft = () => {
    const difference = +new Date('2025-04-11T10:00:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Box
      sx={{
        bgcolor: '#C2D6C5',
        mt: 0,
        width: '100%',
        position: 'relative',
        display: { xs: 'none', md: 'flex' }
      }}
    >
      <Stack
        direction="row"
        spacing={0}
        alignItems='center'
        justifyContent="space-between"
        sx={{ width: '100%', height: '75px' }}
      >
        <Stack direction='row' spacing={2} alignItems='center' sx={{ ml: 10 }}>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>
            {timeLeft.days}
          </Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>{t('countdown.days')}</Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>
            {timeLeft.hours}
          </Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>{t('countdown.hours')}</Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>
            {timeLeft.minutes}
          </Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>{t('countdown.minutes')}</Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>
            {timeLeft.seconds}
          </Typography>
          <Typography variant="h5" sx={{ color: '#3E4F44' }}>{t('countdown.seconds')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems='center' sx={{ mr: 10 }}>
          <Button variant="contained" size="large"
            sx={{
              display: { xs: 'none', md: 'flex' },
              backgroundColor: '#3E4F44',
              color: '#C2D6C5',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#3E4F44',
                boxShadow: 'none'
              }
            }}
            component={Link} to={`/${currentLang}/attend/register-info`}
          >
            {t('countdown.button1')}
          </Button>
          <Button variant="contained" size="large"
            sx={{
              display: { xs: 'none', md: 'flex' },
              backgroundColor: '#3E4F44',
              color: '#C2D6C5',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#3E4F44',
                boxShadow: 'none'
              }
            }}
            component={Link} to={`/${currentLang}/program/call-for-abstracts`}
          >
            {t('countdown.button2')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Countdown;