import React from "react";
import Map, { Marker } from 'react-map-gl';

const MapBox = () => {
  return (
    <Map
      mapboxAccessToken="pk.eyJ1IjoiY2hsb2V5cnciLCJhIjoiY2x6bHlqYzZtMDd5ZDJtb2NwaTMyeTR5bCJ9.uXLaDeaZ24E5h7v1fLfovQ"
      initialViewState={{
        longitude: 117.1386,
        latitude: 34.2032,
        zoom: 14
      }}
      style={{ width: "70%", height: "50vh" }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      <Marker key={"Sheraton Xuzhou Hotel"}
        longitude={117.1386}
        latitude={34.2032}
      >
      </Marker>
    </Map>
  );
};

export default MapBox;