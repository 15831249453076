import React, { useState } from "react";
import {
	Box,
	Typography,
	Stack,
	Paper
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import HeaderBlock from "../utils/HeaderBlock";

const Contact = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Box display="flex" justifyContent="center" sx={{ mt: 5, mb: 3, width: '100%' }}>
				<Stack spacing={0} justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
				<Typography variant="h4" sx={{ color: '#3E4F44', display: 'flex', mb: 2 }}>
					{t("contact.t")}
				</Typography>
				<Typography variant="h5" sx={{ color: '#3E4F44', display: 'flex', mb: 3 }}>
					{t("contact.h")}
				</Typography>
				<Paper square display="flex" justifyContent="center" alignItems="center" sx={{ bgcolor: '#C2D6C5', maxWidth: '800px', width: '75%', height: '50vh', maxHeight: '500px', pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none', mb: 5 }}>
					<Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
						<PersonIcon fontSize="large" sx={{ color: '#5E8D66' }} />
						<Typography variant="h6">
							Prof Qingquan Liu (CUMT)
						</Typography>
						<Stack direction="row" spacing={2}>
							<PhoneIcon sx={{ color: '#5E8D66' }} />
							<Typography variant="body1">
								+86 13852086761
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<LocationOnIcon sx={{ color: '#5E8D66' }} />
							<Typography variant="body1">
								No1 Daxue Road, Xuzhou, Jiangsu, 221116, China
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<EmailIcon sx={{ color: '#5E8D66' }} />
							<Typography variant="body1">
								cumtsafe@cumt.edu.cn
							</Typography>
						</Stack>
					</Stack>
				</Paper>
				<Paper square display="flex" justifyContent="center" alignItems="center" sx={{ bgcolor: '#C2D6C5', maxWidth: '800px', width: '75%', height: '50vh', maxHeight: '500px', pl: 5, pr: 5, pt: 5, pb: 5, boxShadow: 'none', mb: 2 }}>
					<Stack spacing={2} alignItems="center" sx={{ width: '100%' }}>
						<PersonIcon fontSize="large" sx={{ color: '#5E8D66' }} />
						<Typography variant="h6">
							Dr Xin Zhang (UNSW)
						</Typography>
						<Stack direction="row" spacing={2}>
							<PhoneIcon sx={{ color: '#5E8D66' }} />
							<Typography variant="body1">
								+61 466 169 351
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<LocationOnIcon sx={{ color: '#5E8D66' }} />
							<Typography variant="body1">
								Old Main Building, 14 Barker St, Randwick NSW 2052, Australia
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<EmailIcon sx={{ color: '#5E8D66' }} />
							<Typography variant="body1">
								xin.zhang15@unsw.edu.au
							</Typography>
						</Stack>
					</Stack>
				</Paper>
				</Stack>
			</Box>
		</Stack>
	);
};

export default Contact;