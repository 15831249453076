import React, { useState, useEffect } from 'react';
import { Fab, Box, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const FloatingButton = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Zoom in={visible}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          opacity: 0.5
        }}
      >
        <Fab sx={{ color: 'darkgray' }} onClick={scrollToTop} aria-label='Back to top'>
          <KeyboardArrowUpIcon sx={{ color: '#3E4F44' }} />
        </Fab>
      </Box>
    </Zoom>
  );
}

export default FloatingButton;