import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';

const LangSwitcher = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLang = location.pathname.split('/')[1];

  const switchLang = () => {
    var lang = 'en';
    if (currentLang === 'en') {
      lang = 'zh';
    }
    const newPath = location.pathname.replace(`/${currentLang}`, `/${lang}`);
    i18n.changeLanguage(lang);
    navigate(newPath);
  };

  return (
    <div>
      <Button
        startIcon={<TranslateIcon />}
        onClick={() => switchLang()}
        sx={{
          color: '#C2D6C5',
          backgroundColor: 'transparent'
        }}
      >
        {currentLang === 'en' ? '中' : 'En'}
      </Button>
    </div>
  );
};

export default LangSwitcher;