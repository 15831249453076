import React from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Stack
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import Countdown from "./Countdown";

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%'
});

const HeaderBlock = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  // use this to setup Breadcrumbs
  const crumbs = location.pathname.split('/').slice(2);

  return (
    <Stack spacing={0} sx={{ mt: 0, bgcolor: 'white' }}>
      <Box
        sx={{
          bgcolor: '#3E4F44',
          mt: 0,
          width: '100%',
          position: 'relative'
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          alignItems='center'
          justifyContent="space-between"
          sx={{ width: '100%', minHeight: '120px' }}
        >
        <Typography variant="h4" sx={{ color: '#C2D6C5', ml: 15, fontWeight: 400, display: { xs: 'none', md: 'flex' } }}>
          {crumbs.length >= 1 ? t(`header.${crumbs[crumbs.length - 1]}`) : t('header.home')}
        </Typography>
        <Typography variant="h5" sx={{ color: '#C2D6C5', ml: 5, fontWeight: 400, display: { xs: 'flex', md: 'none' } }}>
          {crumbs.length >= 1 ? t(`header.${crumbs[crumbs.length - 1]}`) : t('header.home')}
        </Typography>
        <Img alt='Header Banner' src='/images/header-banner.jpg' sx={{ height: '30vh', mr: 0, display: { xs: 'none', md: 'flex' } }} />
        </Stack>
      </Box>
      <Countdown />
      <Breadcrumbs sx={{ ml: { xs: 5, md: 10 }, mt: 2, mb: 2, color: '#3E4F44' }}>
        <Typography
          variant="body1"
          noWrap
          sx={{
            color: '#3E4F44',
            textDecoration: 'none',
          }}
        >
          {t('header.home')}
        </Typography>
        {crumbs.map((item, index) => (
          <Typography
            variant="body1"
            noWrap
            sx={{
              color: '#3E4F44',
              textDecoration: 'none',
            }}
          >
            {t(`header.${item}`)}
          </Typography>
        ))}
      </Breadcrumbs>
    </Stack>
  );
};

export default HeaderBlock;